<template>
  <div>
    <!-- Success Message -->
    <div v-if="successMessage" class="alert alert-success" style="color: rgb(5, 20, 48)">
      {{ successMessage }}
    </div>

    <b-card>
         <!-- Upload Document Modal -->
         <b-modal v-model="modalVisibleDoument" title="Upload Document" hide-footer>
      <form @submit.prevent="submitDocument">
        <div class="row">
          <div class="col-md-4 col-12">
            <b-form-group id="input-group-2" label="Upload Document:" label-for="documents">
              <div style="margin-left: 3px; margin-bottom: 15px">
                <input type="file" accept=".pdf,.doc,.docx,.png,.jpg,.jpeg" id="documents" @change="onDocumentsChange" />
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <b-button type="submit" variant="primary" class="mb-8 mr-8" :disabled="isLoading">
            <span v-if="!isLoading">Submit</span>
            <b-spinner v-else class="mb-8 mr-8" variant="primary" small></b-spinner>
          </b-button>
        </div>
      </form>
    </b-modal>

      <!-- Rest of the component's template -->
      <!-- Vehicle Information and Approved And Rejected code Button -->
                    
              <b-modal 
                v-model="showVehicleApprovalModal" 
                title="Approve Vehicle Confirmation"
                @hide="resetVehicleApproval"
              >
                <!-- Input Field to Select Vehicle Status -->
                <div class="custom-dropdown">
                  <select 
                    class="form-control" 
                    v-model="newVehicleStatus" 
                    required
                  >
                    <option value="" disabled selected>Select status of vehicle</option>
                    <option value="Approved">Approved</option>
                    <option value="Rejected">Rejected</option>
                  </select>
                </div>

                <!-- Modal Footer Buttons -->
                <template #modal-footer>
                  <b-button variant="primary" @click="confirmVehicleApproval">
                    Confirm
                  </b-button>
                  <b-button variant="primary" @click="cancelVehicleApproval">
                    Cancel
                  </b-button>
                </template>
              </b-modal>


      <!-- Vehicle Information and Approved And Rejected code Ended Button -->
     
      <!-- .....................................................  -->
<!-- Vehicle Information and Upload Button -->
<b-row>
  <div class="col-12 mt-16">
                    <b-form @submit.prevent="addUser" v-if="show">
                        <div style="
                background-color: rgb(97, 116, 152);
                height: 32px;
                border-radius: 4px;
              ">
                            <h4 style="
                  color: rgb(223, 227, 238);
                  margin-left: 5px;
                  font-weight: bold;
                ">
                                Personal Information
                            </h4>
                        </div>
                        <div class="row">
                          <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="First Name:" label-for="first_name">
                                    <b-form-input id="first_name" type="text" placeholder="Enter first name" autocomplete="off"
                                        v-model="first_name" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Middle Name:" label-for="second_name">
                                    <b-form-input id="second_name" type="text" placeholder="Enter  second name" autocomplete="off"
                                        v-model="second_name" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Last Name:" label-for="last_name">
                                    <b-form-input id="last_name" type="text" placeholder="Enter  last name" autocomplete="off"
                                        v-model="last_name" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Second Surname:" label-for="second_last_name">
                                    <b-form-input id="second_last_name" type="text" placeholder="Enter second surname" autocomplete="off"
                                        v-model="second_last_name" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Date of Birth:" label-for="birthdate">
                                    <b-form-input id="birthdate" type="date" placeholder="Enter date of  birth" autocomplete="off"
                                        v-model="birthdate" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Profession:" label-for="profession">
                                    <b-form-input id="profession" type="text" placeholder="Enter profession" autocomplete="off"
                                        v-model="profession" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Voter Credendial Number:" label-for="voter_credential_number">
                                    <b-form-input id="voter_credential_number" type="text" placeholder="Enter voter credential number" autocomplete="off"
                                        v-model="voter_credential_number" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Zip Code:" label-for="postcode">
                                    <b-form-input id="postcode" type="text" placeholder="Enter Zip code"
                                        v-model="postcode" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Address:" label-for="street_address">
                                    <b-form-input id="street_address" type="text" placeholder="Enter street address"
                                        v-model="street_address" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <!-- <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Type:" label-for="type">
                                    <b-form-input id="type" type="text" placeholder="Enter type"
                                        v-model="type" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div> -->

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Email Address:" label-for="email">
                                    <b-form-input id="email" type="email" placeholder="Enter email address"
                                        v-model="email" disabled>
                                    </b-form-input>
                                    <!-- <span class="text-danger" v-if="errors.email">{{ errors.email[0] }}</span> -->
                                </b-form-group>
                            </div>



                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Mobile:" label-for="phone_number">
                                    <b-form-input id="phone_number" type="number" placeholder="Enter mobile number"
                                        v-model="phone_number" disabled></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="City:" label-for="city">
                                    <b-form-input id="city" placeholder="Enter city" v-model="city" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Country:" label-for="country">
                                    <b-form-input id="country" placeholder="Enter country" v-model="country"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Status:" label-for="status">
                                    <b-form-input id="status" placeholder="Enter status" v-model="status"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Profile Picture:" label-for="image">
                                    <div style="margin-left: 3px; margin-bottom: 15px">
                                        <img :src="'https://wossdb.wossautoid.com/' + image
                                            " alt="Profile Picture" width="100" height="100" />
                                    </div>
                                </b-form-group>
                            </div>
                            <!-- Documents  code  here  -->
                            <div class="col-md-4 col-12">
                      <b-form-group id="input-group-2" label="Document:" label-for="documents">
                        <div style="margin-left: 3px; margin-bottom: 15px">
                          <div v-if="editedUser.documents">
                            <a :href="'https://wossdb.wossautoid.com/' + editedUser.documents" target="_blank">
                              <i class="fas fa-file-pdf" style="margin-right: 5px;"></i> PDF
                            </a>
                          </div>
                        </div>
                      </b-form-group>
                    </div>
                        </div>
                    </b-form>
                </div>
      <div class="col-12 mt-16" v-for="(vehicle, index) in vehicles" :key="vehicle.id">
        <b-form v-if="show">
          <div style="background-color: rgb(97, 116, 152); height: 32px; border-radius: 4px;">
            <h4 style="color: rgb(223, 227, 238); margin-left: 5px; font-weight: bold;">
              Vehicle Information ({{ index + 1 }})
            </h4>
          </div>
          <div class="row">
            <div class="col-md-4 col-12">
              <b-form-group id="input-group-2" label="Company Name:" label-for="company_name">
                <b-form-input id="company_name" placeholder="Enter company name" v-model="vehicle.company_name" disabled></b-form-input>
              </b-form-group>
            </div>

            <div class="col-md-4 col-12">
              <b-form-group label="Brand Model:" label-for="model">
                  <b-form-input id="model" placeholder="Enter vehicle model" v-model="vehicle.model.title" disabled></b-form-input>
              </b-form-group>
            </div>

            <div class="col-md-4 col-12">
              <b-form-group id="input-group-2" label="Vehicle Color:" label-for="color">
                <b-form-input id="color" placeholder="Enter vehicle color" v-model="vehicle.color" disabled></b-form-input>
              </b-form-group>
            </div>

            <div class="col-md-4 col-12">
              <b-form-group id="input-group-2" label="License Plate:" label-for="license_plate">
                <b-form-input id="license_plate" placeholder="Enter license plate" v-model="vehicle.license_plate" disabled></b-form-input>
              </b-form-group>
            </div>

            <div class="col-md-4 col-12">
            <b-form-group label="Brand Name:" label-for="make">
                <b-form-input id="brand" placeholder="Enter vehicle brand" v-model="vehicle.brand.name" disabled></b-form-input>
            </b-form-group>
            </div>

            <div class="col-md-4 col-12">
          <b-form-group id="input-group-2" label="Approve Status:" label-for="types">
            <b-form-input
              id="types"
              placeholder="Enter types"
              v-model="vehicle.types"
              :class="statusClass(vehicle.types)"
              disabled
            ></b-form-input>
          </b-form-group>
        </div>


            <div class="col-md-4 col-12">
              <b-form-group id="input-group-2" label="Registration Number:" label-for="registration_number">
                <b-form-input id="registration_number" placeholder="Enter registration number" v-model="vehicle.registration_number" disabled></b-form-input>
              </b-form-group>
            </div>

            <div class="col-md-4 col-12">
              <b-form-group id="input-group-2" label="Vehicle Year:" label-for="year">
                <b-form-input id="year" placeholder="Enter vehicle year" v-model="vehicle.year" disabled></b-form-input>
              </b-form-group>
            </div>

            <div class="col-md-4 col-12">
              <b-form-group id="input-group-2" label="Vehicle Identification Number:" label-for="vin">
                <b-form-input id="vin" placeholder="Enter vehicle identification number" v-model="vehicle.vin" disabled></b-form-input>
              </b-form-group>
            </div>

            <div class="col-md-4 col-12">
              <b-form-group id="input-group-2" label="Unique Identification Code:" label-for="uic">
                <b-form-input id="uic" placeholder="Enter unique identification code" v-model="vehicle.uic" disabled></b-form-input>
              </b-form-group>
            </div>

            <div class="col-md-4 col-12">
              <b-form-group id="input-group-2" label="Vehicle Status:" label-for="status">
                <b-form-input id="status" placeholder="Enter vehicle status" v-model="vehicle.status" disabled></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4 col-12">
                <b-form-group label="Vehicle Images:" label-for="image">
                  <div v-if="vehicle.image && vehicle.image.length" style="margin-left: 3px; margin-bottom: 15px">
                    <img
                      v-for="(img, imgIndex) in vehicle.image"
                      :key="imgIndex"
                      :src="'https://wossdb.wossautoid.com/' + img"
                      alt="Vehicle Image"
                      width="100"
                      height="100"
                      style="margin-right: 10px"
                    />
                  </div>
                </b-form-group>
              </div>
           

              <div class="col-md-4 col-12">
              <b-form-group id="input-group-2" label="Documents:" label-for="documents">
              <div v-if="vehicle.documents && vehicle.documents.length" style="margin-left: 3px; margin-bottom: 15px;">
                <div v-for="(doc, docIndex) in vehicle.documents" :key="docIndex" style="display: flex; align-items: center;">
                  <!-- Check if the document is an image -->
                  <template v-if="isImage(doc.documents)">
                    <img
                      :src="'https://wossdb.wossautoid.com/' + doc.documents"
                      alt="Document"
                      width="100"
                      height="100"
                      style="margin-right: 10px; margin-bottom: 10px;
                      margin-top: 10px;
                      "
                    />
                  </template>
                  <template v-else>
                  <span style="margin-right: 10px; margin-bottom: 10px; margin-top: 10px;">
                    <i class="fas fa-file-pdf" style="margin-right: 5px;"></i>Pdf
                  </span>
                </template>
                  <b-button @click="downloadDocument(doc.documents)" variant="primary" class="btn-sm">
                    Download
                  </b-button>
                </div>
              </div>
            </b-form-group>
          </div>


          </div>

          <b-row>
            <div class="col-12 mt-16">
              <b-form>
                <b-button @click="uploadDocuments(vehicle.id)" variant="primary" class="btn-sm mb-8 mr-2">
                  Upload Document
                </b-button>
                <b-button @click="handleButtonClick(vehicle.id)" variant="primary" class="btn-sm mb-8 mr-2">
                  Approve Vehicle
                </b-button>
              </b-form>
            </div>
          </b-row>
        </b-form>
      </div>
    </b-row>
      <!-- ........................................................  -->
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BForm, BFormGroup, BFormInput, BSpinner, BToast, BModal
} from "bootstrap-vue";
// import axios from "axios";
import axios from "../../../axios";
import Papa from "papaparse";

export default {
data() {
  return {
    show: true,
    modalVisibleDoument: false,
    vehicleId: "",
    fileToUpload: null,
    documents: null,
    isLoading: false,
    successMessage: "",
    first_name: "",
    second_name:"",
    last_name:"",
    second_last_name:"",
    birthdate:"",
    profession:"",
    voter_credential_number:"",
    postcode:"",
    type:"",
    street_address:"",
    documents:"",
    email: "",
    phone_number: "",
    city:"",
    country:"",
    image: "",
    company_name: "",
    model: "",
    color: "",
    license_plate: "",
    brand: "",
    types: "",
    registration_number: "",
    vin: "",
    uic:"",
    year: "",
    status: "",
    userId: "",
    vehicles: [] ,
    image: [],
    image: "",
    showVehicleApprovalModal: false,
      selectedVehicleId: null,
      newVehicleStatus: '',
  };
},
components: {
  BRow, BCol, BCard, BButton, BForm, BFormGroup, BFormInput, BToast, BSpinner, BModal
},

created() {
  const userId = this.$route.params.id;
  axios
    .get(`UserGet/${userId}`)
    .then((response) => {
        this.editedUser = response.data.data;
        // Set the data properties with values from editedUser
        this.first_name = this.editedUser.first_name;
        this.second_name = this.editedUser.second_name;
        this.last_name = this.editedUser.last_name;
        this.second_last_name = this.editedUser.second_last_name;
        this.birthdate = this.editedUser.birthdate;
        this.profession = this.editedUser.profession;
        this.voter_credential_number = this.editedUser.voter_credential_number;
        this.postcode = this.editedUser.postcode;
        this.type = this.editedUser.type;
        this.street_address = this.editedUser.street_address;
        this.phone_number = this.editedUser.phone_number;
        this.city = this.editedUser.city;
        this.email = this.editedUser.email;
        this.country = this.editedUser.country;
        this.status = this.editedUser.status;
        this.image = this.editedUser.image;
        this.documents = this.editedUser.documents;
    
    })
    .catch((error) => {
        console.error("Error fetching user data:", error);
    });

  axios.get(`membershowvehicle/${userId}`)
    .then(response => {
      if (response.data.success) {
        this.vehicles = response.data.data;
        this.userId = vehicles.user_id;
      this.vehicleId = vehicles.id;
      this.company_name = vehicles.company_name;
      this.model = vehicles.model;
      this.color = vehicles.color;
      this.license_plate = vehicles.license_plate;
      this.brand = vehicles.brand;
      this.types = vehicles.types;
      this.registration_number = vehicles.registration_number;
      this.vin = vehicles.vin;
      this.uic = vehicles.uic;
      this.year = vehicles.year;
      this.status = vehicles.status;
      this.image = vehicles.image;
      this.documents = vehicles.documents;
      } else {
        this.successMessage = response.data.message;
      }
    })
    .catch(error => {
      console.error('Error fetching vehicle data:', error);
    });
},
mounted() {
// Example of setting userId from route params or another source
this.userId = this.$route.params.id;
// Or fetch user ID from another method or API if necessary
},
methods: {
  statusClass(types) {
    if (types === 'Approved') {
      return 'text-white bg-success';
    } else if (types === 'Rejected') {
      return 'text-white bg-danger';
    } else {
      return '';
    }
  },
  isImage(fileName) {
    return /\.(jpg|jpeg|png|gif)$/i.test(fileName);
  },
// Approved    vehicle  code  here 

 // Method to handle the button click and open the modal

  handleButtonClick(vehicleId) {
      this.selectedVehicleId = vehicleId;
      this.newVehicleStatus = ''; // Clear the status selection
      this.showVehicleApprovalModal = true;
    },

    // Method to confirm and submit the new vehicle status
    confirmVehicleApproval() {
      if (this.selectedVehicleId && this.newVehicleStatus) {
        const payload = {
          id: this.selectedVehicleId,
          types: this.newVehicleStatus // Sending the selected status (Approved/Rejected)
        };
        this.isLoading = true;
        axios.post(`vehicleUpdate/${this.selectedVehicleId}`, payload)
          .then(response => {
            this.$bvToast.toast(`Vehicle status updated to ${this.newVehicleStatus} successfully!`, {
              title: 'Success',
              variant: 'success',
              solid: true,
              appendToast: true,
              toaster: "b-toaster-top-right",
              autoHideDelay: 5000,
              variant: "primary",
              
            });
            this.resetVehicleApproval();
          })
          .catch(error => {
            console.error('Error updating vehicle status:', error);
            this.$bvToast.toast('Failed to update vehicle status.', {
              title: 'Error',
              variant: 'danger',
              solid: true,
              appendToast: true,
              toaster: "b-toaster-top-right",
              autoHideDelay: 5000,
              variant: "primary",
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },

    // Method to cancel the action and close the modal
    cancelVehicleApproval() {
      this.resetVehicleApproval();
    },

    // Method to reset the modal data
    resetVehicleApproval() {
      this.selectedVehicleId = null;
      this.newVehicleStatus = '';
      this.showVehicleApprovalModal = false;
    },

// Approved  Vehicle  code  ended  here 

  // Other methods...

  fetchData(userId) {
    axios
      .get(`membershowvehicle/${userId}`)
      .then((response) => {
        const vehicleData = response.data.data[0];
        this.company_name = vehicleData.company_name;
        this.model = vehicleData.model;
        this.color = vehicleData.color;
        this.license_plate = vehicleData.license_plate;
        this.make = vehicleData.make;
        this.types = vehicleData.types;
        this.registration_number = vehicleData.registration_number;
        this.vin = vehicleData.vin;
        this.year = vehicleData.year;
        this.status = vehicleData.status;
        this.documents = vehicleData.documents;
        this.image = vehicleData.image;
      })
      .catch((error) => {
        console.error("Error fetching vehicle data:", error);
      });
  },

  uploadDocuments(vehicleId) {
    this.resetUploadDocument();
    this.vehicleId = vehicleId;
    this.modalVisibleDoument = true;
  },

  resetUploadDocument() {
    this.documents = null;
    this.vehicleId = null;
  },

  onDocumentsChange(event) {
    const file = event.target.files[0];
    if (file) {
      this.documents = file;
    }
  },

  
  submitDocument() {
if (!this.userId) {
  console.error("User ID is not set.");
  this.$bvToast.toast("User ID is missing. Please check and try again.", {
    title: "Error",
    variant: "danger",
    solid: true,
    appendToast: true,
    toaster: "b-toaster-top-right",
    autoHideDelay: 5000,
  });
  return;
}

this.isLoading = true;
const formData = new FormData();
formData.append("vehicle_id", this.vehicleId);
// formData.append("user_id", this.userId);
formData.append("documents", this.documents);

axios
  .post("documentStore", formData)
  .then((response) => {
    console.log(response.data);
    this.successMessage = "Document added successfully!";

    // Display the success message as a toast notification
    this.$bvToast.toast(this.successMessage, {
      title: "Success",
      variant: "success",
      solid: true,
      appendToast: true,
      toaster: "b-toaster-top-right",
      autoHideDelay: 5000,
      variant: "primary",
    });

    this.resetUploadDocument();
    const userId = this.$route.params.id;
    this.fetchData(userId);
  })
  .catch((error) => {
    console.error("Error uploading document:", error);
    this.$bvToast.toast("Failed to upload document. Please try again.", {
      title: "Error",
      variant: "primary",
      solid: true,
      appendToast: true,
      toaster: "b-toaster-top-right",
      autoHideDelay: 5000,

    });
  })
  .finally(() => {
    this.isLoading = false;
    this.modalVisibleDoument = false;
  });
},

downloadDocument(fileUrl) {
      // Construct a download link for the file
      const link = document.createElement("a");
      link.href = "https://wossdb.wossautoid.com/" + fileUrl;
      link.download = "downloaded_file"; // Specify the default filename for the downloaded file
      link.target = "_blank"; // Open the link in a new tab
      link.click();
    },

}
};

</script>
